* {
  scroll-behavior: smooth;
}

:root {
  --red: #FF5050;
  --grey: #868686;
  --light-white: #F6F6F6;
  --footer-dark: #282933;
  --white: #ffffff;
}

.slick-next {
  border-radius: 7px;
}

.slick-prev {
  z-index: 2;
  border-radius: 7px;
}


.slick-prev:before,
.slick-next:before {
  color: var(--grey);
}